<template>
  <!-- 单个题目答题详情 -->
  <div class="topicRecord">
    <div class="content">
      <Breadcrumb></Breadcrumb>
      <div class="box" v-loading="loading">
        <div class="content-left">
          <!-- <div class="course-title">
            <div style="width:80%;">
              <div class="name" v-html="detailList.question.title"></div>
              <div class="detailed">
                收藏数：{{detailList.question.collect_num}}
              </div>
            </div>
          </div> -->
          <!-- 左侧下面部分 -->
          <el-tabs type="border-card" v-model="tabsName" @tab-click="tab_change">
            <el-tab-pane label="答题详情" class="item" name="测试点信息" key="1">
              <template v-if="question.type+''==='1'">
                <div class="description" v-code-html="question.description"></div>
                <el-divider></el-divider>
                <template>
                  <div class="yuan_code" style="display: flex;justify-content: space-between;"><div>源代码</div><div >编程语言：{{ record.lang }}</div></div>
                  <CodeEditor class="CodeEditor" ref="CodeEditor" readOnly v-model="record.myAnswer2"></CodeEditor>
                </template>
                <div class="result" v-if="(record.remark.use_cases || []).length">
                  <el-divider></el-divider>
                  <div class="run-result">
                    <div>程序运行结果</div>
                    <div class="button-primary" :style="{ marginLeft:'1rem',padding:'4px 20px'}" @click="download()">
                      下载用例 &nbsp; <i class="el-icon-download"></i>
                    </div>
                  </div>
                  <div class="result-bc">
                    <div class="result-item" v-for="(v,i) in record.remark.use_cases" :key="i" :style="{backgroundColor:v.color}" @click="download(v)">
                      <div class="number">#{{i+1}}</div>
                      <div class="name">{{v.title}}</div>
                      <div class="mem">{{v.time}}/{{v.mem}}</div>
                    </div>
                  </div>
                </div>
                <BCT ref="BCT" :optionsData="{isAnswer:true,topicData:record}" :discuss-show="true" @update="({topicData})=>{record = topicData;}" :alone="true" style="display: none;"></BCT>
              </template>
              <answer :question-info="record" :discuss-show="true" :value="record.myAnswer" is-answer v-else></answer>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="content-right">
          <div class="topic_info">
            <el-form ref="form" :model="form" label-width="100px">
              <el-form-item label="题目编号：">
                <div @click="$handleRoute({id:detailList.question.id},'QuestionInfo')"
                     v-href="{name:'QuestionInfo',query:{id:detailList.question.id}}"
                     class="topic_title">{{detailList.question.id}}</div>
              </el-form-item>
              <el-form-item label="所属题目：">
                <div @click="$handleRoute({id:detailList.question.id},'QuestionInfo')"
                     v-href="{name:'QuestionInfo',query:{id:detailList.question.id}}"
                     class="topic_title" v-html="detailList.question.title"></div>
              </el-form-item>
              <el-form-item label="答题结果：">
                <span :style="{color:record.remark.color||'#000'}">{{record.remark.label}}</span>
                <el-button class="el-button-qing" plain size="mini" :loading="$refs.BCT.loading"
                           @click="refreshCode" style="margin-left: 20px;padding: 7px 7px;top: -3px;position: relative;"
                           v-if="detailList.question.type+''==='1' && record.remark && record.remark.label==='Wait'"
                           icon="el-icon-refresh">点击刷新</el-button>
              </el-form-item>
              <el-form-item label="编程语言：" v-if="detailList.question.type+''==='1'">
                <span>{{record.lang}}</span>
              </el-form-item>
              <el-form-item label="提交时间：">
                <span>{{record._create_time}}</span>
              </el-form-item>
            </el-form>
          </div>

          <el-card style="margin-top: 20px;">
            <el-collapse accordion value="1">
              <el-collapse-item name="1">
                <template slot="title">
                  提交记录
                </template>
                <el-link :disabled="item.id+'' === record.log_id+''" v-for="(item,index) in recordList" :key="index"
                         v-href="{name:$route.name,query:{...$route.query , log_id:item.id}}"
                         @click="$handleRoute({log_id:item.id,id:record.id} , true)">
                  <span>{{ item.grade }}分 — </span><span>提交于：{{ item._create_time }}</span>
                </el-link>
                <div v-if="recordCount > 10" style="text-align: right;">
                  <el-link type="primary"
                           @click="$handleRoute({id:detailList.question.id,is_answer:1},'QuestionInfoRecord')"
                           v-href="{name:'QuestionInfoRecord',query:{id:detailList.question.id,is_answer:1}}"
                  >更多</el-link>
                </div>
              </el-collapse-item>
            </el-collapse>
          </el-card>
        </div>
      </div>
    </div>
    <Payment ref="Payment" :order_num="pamr.order_num" :mobile="pamr.mobile" :price="pamr.price"></Payment>
  </div>
</template>

<script>

import CodeEditor from "@/components/CodeEditor/CodeEditor.vue"
import Advertising from "@/components/Advertising/Advertising.vue"
import HotRecommend from "@/views/api/com/hotRecommend";
import Payment from "@/components/Payment/Payment.vue"
import { detail,  d_record , refer_record } from "@/api/topic.js"
import Grid from "@/components/Grid/index.vue"
import TopicOperate from "@/components/TopicOperate/TopicOperate.vue"

import { orderCreate } from "@/api/common.js"
import { mapState } from "vuex"
import BCT from "@/components/TopicType/BCT";
import Answer from "@/views/api/teaching/question/c/answer";
export default {
  components: {
    Answer,
    BCT,
    CodeEditor,
    Advertising, Grid,
    HotRecommend, Payment,TopicOperate
  },
  data () {
    return {
      pamr: {
        order_num: '',
        mobile: '',
        price: 0
      },
      loading: false,
      defaultValue: '',
      form: {},
      detailList: {
        title: '',

        question: { description: '', use_cases: [], user_use_cases: {} }
      },
      question: { user_use_cases: { use_cases: [] }, question: { description: '' } },
      tabsName: '测试点信息',
      record: { remark: {use_cases:[]} },
      contentRightHeight:0,
      recordList:[],
      recordCount:0,
      buyTime:null,
      id:0,
      log_id:0
    }
  },
  computed: {
    ...mapState(["pageOptions"]),
  },
  created () {
    

    let { log_id, id } = this.$route.query
    this.log_id=log_id
    this.id=id
    console.log(log_id,"log_id")
    console.log(id,"id")
    this.getDetail(log_id, id)
   
  },
  mounted(){
    // if(location.href.indexOf("#reloaded")==-1){
    //     location.href=location.href+"#reloaded";
    //     location.reload();
    // }
  },
  beforeDestroy(){
    this.stopSetInterval()
  },
  destroyed() {
    this.stopSetInterval()
  },
  methods: {
    createSetInterval(log_id,id) {
      this.stopSetInterval()
      let _this = this
      this.timer = setInterval(() => {
        _this.get_d_record(log_id,id)
      }, 1000)
    },
    // 关闭轮询
    stopSetInterval() {
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
    },
    userInfoListener(){
      this.getRecords(this.$route.query.id);
    },
    routeUpdate(){
      let { log_id, id } = this.$route.query
      this.getDetail(log_id, id);
      this.getRecords(id);
    },
    getRecords(id){
      // if(id && this.userInfo.id && this.recordList.length === 0){
      if(id && this.userInfo.id){
        refer_record({ answer_user_id:this.userInfo.id, id:id , page_size:10,page:1 }).then(res=>{
          this.recordList = res.data.data
          this.recordCount = res.data.total
        });
      }
    },
    refreshCode(){
      this.$refs.BCT.click_record()
      console.log('55555')
      this.get_d_record(this.log_id,this.id)
    },
    //测试用例下载
    download (item) {
      item = item || {};
	   var id = this.detailList.question.id;
      if (this.detailList.question.is_buy+'' === '1') {
        if(item.name){
          window.location.href = encodeURI((location.baseURL || '') + `/api/v1_0_0.question/cases_down?name=${item.name}&id=${id}&user_id=${localStorage.getItem('user_id')}&user_token=${encodeURIComponent(localStorage.getItem('user_token'))}`)
        }else{
          window.location.href = encodeURI((location.baseURL || '') + `/api/v1_0_0.question/cases_down?id=${id}&user_id=${localStorage.getItem('user_id')}&user_token=${encodeURIComponent(localStorage.getItem('user_token'))}`)
        }
      } else {
        //创建订单
        orderCreate({
          type: 1,
          id: id,
        }).then((res) => {
          this.$refs.Payment.show = true
          this.pamr = {
            order_num: res.data.order.order_num,
            mobile: res.data.order.mobile,
            price: res.data.order.price
          }
        })
      }
    },
    //获取答题详情
    get_d_record (log_id, id , callback=null) {
      // return
      // this.loading = true;
      d_record({ log_id, id }).then(res => {
        // this.loading = false;
        let datas = res.data.info;
        let record = this.question;
        record.log_id = datas.log_id || datas.id;
        record.children = datas._list || this.detailList.children || [];
        record._create_time = datas._create_time;
        record.remark = datas.remark;
        record.lang = datas.lang;
        var myAnswer = [];
        if(datas.answers){
          myAnswer = {...datas.answers};
        }else if(datas.answer instanceof Array){
          myAnswer = datas.answer.map(v => {
            if( datas.type+'' === '3'){
              let ls =  v.split('：');
              return (ls.length > 1 ? ls[1] : v).split('、');
            }else{
              return v;
            }
          });
        }else if(datas.answer instanceof Object){
          myAnswer = [];
        }else {
          myAnswer = datas.answer;
        }
        if( datas.type+'' === '3'){
          record.myAnswer = {};
          for (let i = 0; i < record.children.length; i++) {
            let q = record.children[i];
            record.children[i] = {...q,type:record.type,myAnswer:(myAnswer[q.id] || {}).answer,remark:(myAnswer[q.id] || {}).remark};
            record.myAnswer[q.id] = {quest_id:q.id,answer:(myAnswer[q.id] || {}).answer};
          }
        }else{
          record.myAnswer = {[record.id]:{quest_id:record.id,answer:myAnswer,language:record.language}};
        }
        record.my_grade = datas.grade;
        console.log("get_d_record" , record);
      
       record.myAnswer2= record.myAnswer[Object.keys(record.myAnswer)[0]].answer;
        // console.log(Data,"Data");
        // record.myAnswer = 'myAnswer: ' + JSON.stringify(Data); 
        this.record = record;

        console.log(this.record,"this.recordthis.record");
        if(this.record && log_id && id && this.record.remark && (this.record.remark || {}).label === 'Wait'){
            console.log("等待中~~~~~~~~~~")
            this.createSetInterval(log_id,id)
          }else{
            this.getRecords(this.$route.query.id);
            console.log("出结果了")
            this.stopSetInterval()

            this.$forceUpdate()
            // this.get_d_record(log_id, id)

          }

      }).finally(() => {
        this.loading = false
      })
    },
    getDetail (log_id, id) {
      this.loading = true;
      detail({ id }).then(res => {
        let question = (res.data || {}).question || {}
        let children = (res.data || {}).children || []
        let options = (res.data || {}).options || []
        this.question = {...question,options}
        this.detailList = {...res.data,children,question}
        this.get_d_record(log_id, id)
      }).finally(() => {
        // this.loading = false
      })
    },
    tab_change () {
      let { log_id, id } = this.$route.query
      this.get_d_record(log_id, id)
    },
  }
};
</script>

<style lang="less" scoped>
::v-deep{
  .el-form-item__content,.el-form-item__label{
    line-height: 30px;
    margin-top: 0.3rem;
  }
  .el-collapse-item__header,.el-collapse-item__wrap{
    border: none;
  }
  .el-collapse-item__content{
    padding-bottom: 0;
  }
}
.box {
  .el-collapse{
    border: none;
  }
  .el-link--primary{
    color: #00957e;
  }
  .el-link--default:hover{
    color: #00957e;
  }
  .el-link.is-underline:hover:after{
    border-bottom-color: #00957e;
  }
  .result-bc {
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-around;
    .result-item {
      cursor: pointer;
      margin: 16px;
      color: #fff;
      width: 90px;
      height: 90px;
      padding: 14px;
      border-radius: 10px;
      .name {
        text-align: center;
        font-size: 1.8rem;
        font-weight: 600;
        line-height: 3rem;
      }
      .mem {
        text-align: center;
        font-size: 12px;
      }
    }
  }
  .yuan_code {
    margin: 1rem 0;
  }
  .topic_title {
    line-height: 30px !important;
    cursor: pointer;
    color: #00957e;
  }
  ::v-deep {
    .el-form-item {
      margin-bottom: 10px;
    }
  }
  display: flex;
  justify-content: space-between;

  .content-left {
    width: 70%;
    ::v-deep {
      .el-tabs--border-card {
        border-radius: 10px;
      }
      .el-tabs__item {
        font-size: 16px;
        height: 50px;
        width: 130px;
        line-height: 50px;
        text-align: center;
      }
      .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
        color: #00957e;
      }
      .el-tabs__item:hover {
        color: #00957e;
      }
    }
    .item {
      padding: 1rem 2rem;
      min-height: 10rem;
    }
    .course-title {
      padding: 2rem;
      background: #ffffff;
      border-radius: 10px;
      border: 1px solid #dbdbdb;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;
      .edit {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .feedback {
          display: flex;
          align-items: center;
          font-size: 14px;
          cursor: pointer;
          margin-right: 0.8rem;
        }
        .el-icon-edit-outline,
        .el-icon-star-off,
        .el-icon-star-on {
          font-size: 20px;
          margin-right: 0.2rem;
        }
      }
      .name {
        font-size: 18px;
        color: #0a0a0a;
        line-height: 29px;
        margin-bottom: 1rem;
        white-space: pre-wrap;
      }
      .detailed {
        font-size: 14px;
        color: #0a0a0a;
        line-height: 20px;
        > span {
          margin-right: 1.5rem;
        }
      }
    }
    .result {
      padding: 1rem 0rem;
      .run-result {
        display: flex;
        align-items: center;
      }
      .list-item {
        display: flex;
        font-size: 14px;
        color: #2a2a2a;
        align-items: center;
        margin: 1rem 0;
        .item-right {
          display: flex;
          align-items: center;
          .item-result {
            padding: 4px 6px;
            background-color: #00957e;
            color: #fff;
            margin-left: 0.5rem;
          }
        }
      }
    }
  }
  .content-right {
    width: 27%;
    .topic_info {
      border: 1px solid #dbdbdb;
      background-color: #fff;
      padding: 1rem 0.5rem;
    }
    .margin {
      margin-top: 40px;
    }
  }
}
</style>
